<template>
  <div id="improgress-card">
    <div class="inprogress-wrapper">
      <div
        v-for="(card, index) in cards"
        :key="index"
        class="inprogress-card"
        :class="{ inprogress__active: card.value === currentTab }"
        @click="changeTab(card.value)"
      >
        <div class="inprogress-card__icon" :class="getClassByValue(card.value)">
          <span class="svg-icon">
            <inline-svg :src="card.icon" />
          </span>
        </div>
        <div class="inprogress-card__info ic-info">
          <h5 class="ic-info__name">{{ card.text }}</h5>
          <div class="ic-info__des">{{ card.quantily }} khách hàng</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { INPROGRESS_TYPES } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('groupDashboard');

export default {
  name: 'InprogressCards',

  data() {
    return {
      currentTab: INPROGRESS_TYPES.TOTAL,
      cards: [
        {
          text: 'Tổng',
          icon: '/media/svg/icons/Customer_page/All.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.TOTAL,
        },
        {
          text: 'Mới vào',
          icon: '/media/svg/icons/Customer_page/customer_new.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.NEW,
        },
        {
          text: 'Tiến độ: Cần cải thiện',
          icon: '/media/svg/icons/Customer_page/improve.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.IMPROVE,
        },
        {
          text: 'Cần đặt lịch 1 - n',
          icon: '/media/svg/icons/Customer_page/1_n.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.ONE_N,
        },
        {
          text: 'Đường huyết cao',
          icon: '/media/svg/icons/Customer_page/hight_blood_sugar.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.HIGHT_BLOOD_SUGAR,
        },
        {
          text: 'Cần ưu tiên hỗ trợ',
          icon: '/media/svg/icons/Customer_page/support.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.SUPPORT,
        },
        {
          text: 'Sắp kết thúc',
          icon: '/media/svg/icons/Customer_page/customer_expire.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.END,
        },
        {
          text: 'Tiến độ: Ngưng học',
          icon: '/media/svg/icons/Customer_page/stop_learning.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.STOP_STUDY,
        },
        {
          text: 'Cần đặt lịch 1 - 1',
          icon: '/media/svg/icons/Customer_page/1_1.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.ONE_ONE,
        },
        {
          text: 'Đường huyết thấp',
          icon: '/media/svg/icons/Customer_page/low_blood_sugar.svg',
          quantily: 0,
          value: INPROGRESS_TYPES.LOW_BLOOD_SUGAR,
        },
      ],
    };
  },

  computed: {
    ...mapState(['statistical']),
  },

  watch: {
    statistical: {
      handler(val) {
        if (val) {
          this.cards[0].quantily = val.totalCount;
          this.cards[1].quantily = val.recentActivedCount;
          this.cards[2].quantily = val.needImpoveCount;
          this.cards[3].quantily = val.booking1NCount;
          this.cards[4].quantily = val.glucoseHighCount;
          this.cards[5].quantily = val.needSupportCount;
          this.cards[6].quantily = val.commingExpiredCount;
          this.cards[7].quantily = val.pendingCount;
          this.cards[8].quantily = val.booking11Count;
          this.cards[9].quantily = val.glucoseLowCount;
        } else {
          this.cards[0].quantily = 0;
          this.cards[1].quantily = 0;
          this.cards[2].quantily = 0;
          this.cards[3].quantily = 0;
          this.cards[4].quantily = 0;
          this.cards[5].quantily = 0;
          this.cards[6].quantily = 0;
          this.cards[7].quantily = 0;
          this.cards[8].quantily = 0;
          this.cards[9].quantily = 0;
        }
      },
      deep: true,
    },
  },

  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      this.$emit('search', tab);
    },

    getClassByValue(value) {
      switch (value) {
        case INPROGRESS_TYPES.NEW:
        case INPROGRESS_TYPES.END:
          return 'inprogress-card__icon__success';
        case INPROGRESS_TYPES.IMPROVE:
        case INPROGRESS_TYPES.STOP_STUDY:
          return 'inprogress-card__icon__warning';
        case INPROGRESS_TYPES.HIGHT_BLOOD_SUGAR:
        case INPROGRESS_TYPES.LOW_BLOOD_SUGAR:
          return 'inprogress-card__icon__danger';

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inprogress-card {
  display: flex;
  align-items: center;
  gap: 16px;

  background-color: #fff;
  padding: 12px 8px;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid #fff;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  &__icon {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #e4f5f5;

    &__success {
      background-color: #e6f6ed !important;
    }

    &__warning {
      background-color: #fde6d2 !important;
    }

    &__danger {
      background-color: #ffe3e3 !important;
    }
  }

  &.inprogress__active {
    border-color: #008479;
  }
}

.ic-info {
  &__name {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #050505;
  }
  &__des {
    font-size: 13px;
    line-height: 20px;
    color: #333333;
  }
}

.inprogress-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}
</style>
